import React from 'react'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'
import OurProcess from '../components/OurProcess/OurProcess'

export const ourProcessPage = graphql`
  query ourProcessPageQuery {
    sanityPage(slug: {current: {eq: "our-process"}}) {
      pageBanner {
        title
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
	  }
  }
`

const ourProcess = ({data}) => {
    return (
        <Layout>
            <Banner 
                heading={ data.sanityPage.pageBanner.title }
                image={ data.sanityPage.pageBanner.image.asset.fluid }
                bannerAlt="banner--sm"
            />
            <OurProcess />
        </Layout>
    )
}

export default ourProcess
