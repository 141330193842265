import React from 'react'

import imageOne from '../../images/harvest.jpg'
import imageTwo from '../../images/crush.jpg'
import imageThree from '../../images/fermentaion.jpg'
import imageFour from '../../images/press.jpg'
import imageFive from '../../images/clarification.jpg'
import imageSix from '../../images/bottling.jpg'

const OurProcess = () => {
    return (
        <div className="process">
            <div className="container">
                <div className="process__steps">
                    <div className="process__step">
                        <div className="process__copy">
                            <div className="process__body">
                                <h3 className="process__heading">
                                    Step One - Harvest
                                </h3>
                                <p>
                                    When grapes are ripe, it is time to pick.
                                </p>
                                <p>
                                    All of our grapes are harvested by hand.  Hand harvesting ensures that only the best quality clusters are picked.  Here, vineyard owner Tom Colburn hand picks his grapes to our exact specification. 
                                </p>
                                <p>
                                    After picking, grapes must be processed as quickly as possible in order to preserve the high quality of the grape.
                                </p>
                            </div>
                        </div>
                        <div className="process__img">
                            <img src={imageOne} alt=""/>
                        </div>
                    </div>
                    <div className="process__step">
                        <div className="process__copy">
                            <div className="process__body">
                                <h3 className="process__heading">
                                    Step Two - Crush
                                </h3>
                                <p>
                                    When the grapes are removed from the stems, they are gently creased to put the juice in contact with the grape skins.
                                </p>
                                <h4 className="process__subheading">
                                    Cold Soak for Red or Cold processing for White
                                </h4>
                                <p>
                                    For Red varietals, dry ice is added to the grapes to prevent the beginning of fermentation.  The contact between the juice and the skins give the wine its vibrant color.
                                </p>
                                <p>
                                    White wines are processed more quickly than reds.  White grapes are taken immediately from harvest to press and kept cold during fermentation and stabilization.  The cold conditions and minimal exposure to air help to avoid oxidation and preserve color and clarity of the fruit. 
                                </p>
                            </div>
                        </div>
                        <div className="process__img">
                            <img src={imageTwo} alt=""/>
                        </div>
                    </div>        
                    <div className="process__step">
                        <div className="process__copy">
                            <div className="process__body">
                                <h3 className="process__heading">
                                    Step Three - Fermentation
                                </h3>
                                <p>
                                    Yeast is added to the grapes which converts sugar to alcohol turning grape juice into wine.
                                </p>
                            </div>
                        </div>
                        <div className="process__img">
                            <img src={imageThree} alt=""/>
                        </div>
                    </div> 
                    <div className="process__step">
                        <div className="process__copy">
                            <div className="process__body">
                                <h3 className="process__heading">
                                    Step Four - Press
                                </h3>
                                <p>
                                    Once fermentation is complete, the wine is then pressed from the skins and stored into Oak barrels.  
                                </p>
                            </div>
                        </div>
                        <div className="process__img">
                            <img src={imageFour} alt=""/>
                        </div>
                    </div> 
                    <div className="process__step">
                        <div className="process__copy">
                            <div className="process__body">
                                <h3 className="process__heading">
                                    Step Five - Clarification
                                </h3>
                                <p>
                                    Gravity and time cause the solid particles to sink to the bottle of the barrel or carboy.  The clear liquid is then “racked off” or separated from the sediment so the resulting wine is beautifully clear and free of all but the finest solid particles.
                                </p>
                            </div>
                        </div>
                        <div className="process__img">
                         <img src={imageFive} alt=""/>
                        </div>
                    </div> 
                    <div className="process__step">
                        <div className="process__copy">
                            <div className="process__body">
                                <h3 className="process__heading">
                                    Step Six - Bottling (aging)
                                </h3>
                                <p>
                                    Bottling is possibly the most enjoyable time in the winemaking process.  At bottling time, we have a near finished and very drinkable product.  Bottling can be done by hand with a gravity flow system as seen here or by mechanized system.  Often a self-contained “bottling truck” is brought to the production site and the entire process of filling the bottles, corking the bottles and labeling the bottles is completed in the production line, all of which is contained within the trailer of the truck.
                                </p>
                                <p>
                                    After bottling, bottles must “rest” for at least a month to several months before drinking.  Resting the wine allows the wine to settle and helps to avoid “shock” or a temporary instability in the wine which can occur during the bottling process.  Shock can affect not only the taste of the wine but also its clarity.
                                </p>
                            </div>
                        </div>
                        <div className="process__img">
                            <img src={imageSix} alt="" />
                        </div>
                    </div> 
                </div>
            </div>
        </div>

    )
}

export default OurProcess
